import { ITractableTheme } from "@tractable/frame-ui";

/** Common styles used across numerous components */
export const commonStyles = (theme: ITractableTheme) => {
  return {
    button: {
      backgroundColor: theme.colour.Purple60,
      borderRadius: "4px",
      borderWidth: "1px",
      borderColor: theme.colour.Black,
      "&:hover, &:disabled": {
        backgroundColor: theme.colour.Purple60,
      },
    },
    cancelButton: {
      color: theme.colour.Purple60,
      marginRight: "24px",
      "&:hover": {
        backgroundColor: theme.colour.White,
      },
    },
  };
};
