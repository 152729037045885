import CryptoJS from "crypto-js";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { RuntimeConfigContext } from "../context/ConfigProvider";
import { TokensContext } from "../context/TokensProvider";

export const LOCAL_STORAGE_AUTH_STATE_KEY =
  "tractable-auth-management-auth-state";
export const LOCAL_STORAGE_CODE_VERIFIER_KEY =
  "tractable-auth-management-code-verifier";

// If the user is not logged in, redirect them to the Azure AD login page
const Auth: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const { tokens } = TokensContext.useValue();
  const config = RuntimeConfigContext.useValue();

  useEffect(() => {
    const run = () => {
      if (!tokens) {
        const state = uuidv4();

        // See https://www.rfc-editor.org/rfc/rfc7636 for info on PKCE
        const codeVerifier = uuidv4();
        const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(
          CryptoJS.enc.Base64url
        );

        localStorage.setItem(LOCAL_STORAGE_AUTH_STATE_KEY, state);
        localStorage.setItem(LOCAL_STORAGE_CODE_VERIFIER_KEY, codeVerifier);

        const url = `https://${config.cognito.domain}/oauth2/authorize?identity_provider=AzureADSAML&redirect_uri=${config.cognito.redirectUrl}&response_type=CODE&client_id=${config.cognito.appClientId}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        window.location.replace(url);
      }
    };

    run();
  }, [tokens]);

  const AuthWrapper: React.FC<{ children: React.ReactNode }> = useCallback(
    ({ children: _children }) => {
      return tokens ? <>{_children}</> : null; // Don't render anything if there is no logged-in user
    },
    [tokens]
  );

  return (
    <AuthWrapper>
      <>{children}</>
    </AuthWrapper>
  );
};

export default Auth;
