import { NotificationsProvider } from "@mantine/notifications";
import { TractableTheme } from "@tractable/frame-ui";
import { Route, Routes } from "react-router-dom";
import Auth from "./auth/Auth";
import SAMLCallback from "./auth/SamlCallback";
import { FrameToJssThemeProvider } from "./components/FrameToJssThemeProvider";
import Shell from "./components/Shell";
import UserList from "./components/UserList";
import { ApiClientProvider } from "./context/ApiClientProvider";
import { ConfigProvider } from "./context/ConfigProvider";
import { TokensProvider } from "./context/TokensProvider";
import { UserPoolProvider } from "./context/UserPoolProvider";

const App: React.FC = () => {
  // The auth-protected part of the app; everything in here will require the
  // user to be signed in.
  const authProtectedApp = (
    <Auth>
      <UserPoolProvider>
        <TractableTheme>
          <FrameToJssThemeProvider>
            <NotificationsProvider position="bottom-center">
              <Shell>
                <Routes>
                  <Route path="/" element={<UserList />} />
                </Routes>
              </Shell>
            </NotificationsProvider>
          </FrameToJssThemeProvider>
        </TractableTheme>
      </UserPoolProvider>
    </Auth>
  );

  return (
    <ConfigProvider>
      <ApiClientProvider>
        <TokensProvider>
          <Routes>
            <Route path="/saml_callback" element={<SAMLCallback />} />
            <Route path="*" element={authProtectedApp} />
          </Routes>
        </TokensProvider>
      </ApiClientProvider>
    </ConfigProvider>
  );
};

export default App;
