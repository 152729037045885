/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeCodeRequest
 */
export interface ExchangeCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeRequest
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeRequest
     */
    redirectUri?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeRequest
     */
    grantType: ExchangeCodeRequestGrantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeRequest
     */
    codeVerifier?: string;
}

/**
* @export
* @enum {string}
*/
export type ExchangeCodeRequestGrantTypeEnum =
    'authorization_code' | 
    'refresh_token'



/**
 * Check if a given object implements the ExchangeCodeRequest interface.
 */
export function instanceOfExchangeCodeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grantType" in value;

    return isInstance;
}

export function ExchangeCodeRequestFromJSON(json: any): ExchangeCodeRequest {
    return ExchangeCodeRequestFromJSONTyped(json, false);
}

export function ExchangeCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'redirectUri': !exists(json, 'redirectUri') ? undefined : json['redirectUri'],
        'grantType': json['grantType'],
        'codeVerifier': !exists(json, 'codeVerifier') ? undefined : json['codeVerifier'],
    };
}

export function ExchangeCodeRequestToJSON(value?: ExchangeCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'refreshToken': value.refreshToken,
        'redirectUri': value.redirectUri,
        'grantType': value.grantType,
        'codeVerifier': value.codeVerifier,
    };
}

