/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendInviteRequest
 */
export interface ResendInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof ResendInviteRequest
     */
    username: string;
    /**
     * Product ID for the product to invite the user to
     * @type {string}
     * @memberof ResendInviteRequest
     */
    productId: string;
    /**
     * URL to redirect user to after they confirm their account
     * @type {string}
     * @memberof ResendInviteRequest
     */
    productUrl: string;
    /**
     * Tractable environment
     * @type {string}
     * @memberof ResendInviteRequest
     */
    env: string;
    /**
     * 
     * @type {string}
     * @memberof ResendInviteRequest
     */
    clientId: string;
}


/**
 * Check if a given object implements the ResendInviteRequest interface.
 */
export function instanceOfResendInviteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productUrl" in value;
    isInstance = isInstance && "env" in value;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function ResendInviteRequestFromJSON(json: any): ResendInviteRequest {
    return ResendInviteRequestFromJSONTyped(json, false);
}

export function ResendInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendInviteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'productId': json['productId'],
        'productUrl': json['productUrl'],
        'env': json['env'],
        'clientId': json['clientId'],
    };
}

export function ResendInviteRequestToJSON(value?: ResendInviteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'productId': value.productId,
        'productUrl': value.productUrl,
        'env': value.env,
        'clientId': value.clientId,
    };
}

