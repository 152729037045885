import { showNotification } from "@mantine/notifications";
import { colours } from "@tractable/frame-ui/build/theme/colour";

const _notify = (level: "info" | "error") => (message: string) => {
  const backgroundColor = level === "info" ? colours.black : colours.red[30];
  const textColor = level === "info" ? colours.white : colours.black;
  showNotification({
    autoClose: level === "info" ? 2000 : false,
    disallowClose: level === "info",
    message,
    styles: () => ({
      root: {
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        // Hide the default blue bar on the left side of the popup
        "&::before": { display: "none" },
      },
      description: { color: textColor },
      closeButton: { color: textColor },
    }),
  });
};

export const info = _notify("info");
export const error = _notify("error");
