/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdToken } from './IdToken';
import {
    IdTokenFromJSON,
    IdTokenFromJSONTyped,
    IdTokenToJSON,
} from './IdToken';

/**
 * 
 * @export
 * @interface ExchangeCodeResponse
 */
export interface ExchangeCodeResponse {
    /**
     * 
     * @type {object}
     * @memberof ExchangeCodeResponse
     */
    accessToken: object;
    /**
     * 
     * @type {IdToken}
     * @memberof ExchangeCodeResponse
     */
    idToken: IdToken;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeResponse
     */
    rawIdToken: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeResponse
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeCodeResponse
     */
    tokenType: string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeCodeResponse
     */
    expiresIn: number;
}


/**
 * Check if a given object implements the ExchangeCodeResponse interface.
 */
export function instanceOfExchangeCodeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;
    isInstance = isInstance && "idToken" in value;
    isInstance = isInstance && "rawIdToken" in value;
    isInstance = isInstance && "tokenType" in value;
    isInstance = isInstance && "expiresIn" in value;

    return isInstance;
}

export function ExchangeCodeResponseFromJSON(json: any): ExchangeCodeResponse {
    return ExchangeCodeResponseFromJSONTyped(json, false);
}

export function ExchangeCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeCodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'idToken': json['idToken'],
        'rawIdToken': json['rawIdToken'],
        'refreshToken': !exists(json, 'refreshToken') ? undefined : json['refreshToken'],
        'tokenType': json['tokenType'],
        'expiresIn': json['expiresIn'],
    };
}

export function ExchangeCodeResponseToJSON(value?: ExchangeCodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'idToken': value.idToken,
        'rawIdToken': value.rawIdToken,
        'refreshToken': value.refreshToken,
        'tokenType': value.tokenType,
        'expiresIn': value.expiresIn,
    };
}

