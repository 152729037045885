import { Divider, SimpleGrid } from "@mantine/core";
import { User } from "@tractableai/auth-management-client";
import { createUseStyles } from "react-jss";
import { statusName } from "../i18n";

type UserDetailsProps = {
  user: User;
  clientId: string;
};

const UserDetails = (props: UserDetailsProps) => {
  const { user, clientId } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Divider className={classes.divider} />
      <SimpleGrid cols={2}>
        <div>First Name</div>
        <div>{user.givenName}</div>
        <div>Last Name</div>
        <div>{user.familyName}</div>
        <div>Client</div>
        <div>{clientId}</div>
        <div>Status</div>
        <div>{statusName(user.status)}</div>
        <div>Email address</div>
        <div>{user.email}</div>
        <div>Date created</div>
        <div>
          {new Date(user.createdOn).toLocaleDateString([], {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </div>
        <div>Authentication</div>
        <div>
          {user.status === "external_user" ? "Single sign-on" : "Email"}
        </div>
        <div>Cognito ID</div>
        <div>{user.userId}</div>
      </SimpleGrid>
    </div>
  );
};

const useStyles = createUseStyles(() => ({
  container: {
    paddingLeft: "32px",
    paddingRight: "56px",
    paddingTop: "24px",
    paddingBottom: "64px",
  },
  divider: {
    marginBottom: "24px",
  },
}));

export default UserDetails;
