import { IdToken } from "@tractableai/auth-management-client";
import { PropsWithChildren, useCallback, useState } from "react";
import { createSafeContext } from "react-safe-context";

export type Tokens = {
  // TODO(kevin): real types
  accessToken: object;
  idToken: IdToken;
};

type TokensContext = {
  tokens?: Tokens;
  setTokens?: (u: Tokens) => void;
};

/** Context for the Cognito access and ID tokens */
export const TokensContext = createSafeContext<TokensContext>("TokensContext");

export const TokensProvider: React.FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [tokens, setTokens] = useState<Tokens | undefined>(undefined);

  const setTokensSafe = useCallback(
    (newTokens: Tokens) => {
      JSON.stringify(newTokens) !== JSON.stringify(tokens) &&
        setTokens(newTokens);
    },
    [tokens]
  );

  return (
    <TokensContext.Provider value={{ tokens, setTokens: setTokensSafe }}>
      {children}
    </TokensContext.Provider>
  );
};
