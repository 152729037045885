/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponseContent,
  CreateUsersRequest,
  DeleteUserRequest,
  DescribeUserPoolResponse,
  ExchangeCodeRequest,
  ExchangeCodeResponse,
  ListUserPoolsResponse,
  ListUsersResponse,
  ResendInviteRequest,
  ResetPasswordRequest,
} from '../models';
import {
    ApiErrorResponseContentFromJSON,
    ApiErrorResponseContentToJSON,
    CreateUsersRequestFromJSON,
    CreateUsersRequestToJSON,
    DeleteUserRequestFromJSON,
    DeleteUserRequestToJSON,
    DescribeUserPoolResponseFromJSON,
    DescribeUserPoolResponseToJSON,
    ExchangeCodeRequestFromJSON,
    ExchangeCodeRequestToJSON,
    ExchangeCodeResponseFromJSON,
    ExchangeCodeResponseToJSON,
    ListUserPoolsResponseFromJSON,
    ListUserPoolsResponseToJSON,
    ListUsersResponseFromJSON,
    ListUsersResponseToJSON,
    ResendInviteRequestFromJSON,
    ResendInviteRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
} from '../models';

export interface CreateUsersOperationRequest {
    userPoolId: string;
    createUsersRequest?: CreateUsersRequest;
}

export interface DeleteUserOperationRequest {
    userPoolId: string;
    deleteUserRequest?: DeleteUserRequest;
}

export interface DescribeUserPoolRequest {
    userPoolId: string;
}

export interface ExchangeCodeOperationRequest {
    exchangeCodeRequest?: ExchangeCodeRequest;
}

export interface ListUsersRequest {
    userPoolId: string;
    search?: string;
    includeAttributes?: boolean;
    paginationToken?: string;
    pageSize?: number;
}

export interface ResendInviteOperationRequest {
    userPoolId: string;
    resendInviteRequest?: ResendInviteRequest;
}

export interface ResetPasswordOperationRequest {
    userPoolId: string;
    resetPasswordRequest?: ResetPasswordRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createUsersRaw(requestParameters: CreateUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling createUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user_pools/{userPoolId}/users`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUsersRequestToJSON(requestParameters.createUsersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createUsers(requestParameters: CreateUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createUsersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user_pools/{userPoolId}/users`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUserRequestToJSON(requestParameters.deleteUserRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(requestParameters: DeleteUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async describeUserPoolRaw(requestParameters: DescribeUserPoolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DescribeUserPoolResponse>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling describeUserPool.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user_pool/{userPoolId}`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DescribeUserPoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async describeUserPool(requestParameters: DescribeUserPoolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DescribeUserPoolResponse> {
        const response = await this.describeUserPoolRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangeCodeRaw(requestParameters: ExchangeCodeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangeCodeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authentication/code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExchangeCodeRequestToJSON(requestParameters.exchangeCodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeCodeResponseFromJSON(jsonValue));
    }

    /**
     */
    async exchangeCode(requestParameters: ExchangeCodeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangeCodeResponse> {
        const response = await this.exchangeCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listUserPoolsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUserPoolsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user_pools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUserPoolsResponseFromJSON(jsonValue));
    }

    /**
     */
    async listUserPools(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUserPoolsResponse> {
        const response = await this.listUserPoolsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUsersResponse>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling listUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.includeAttributes !== undefined) {
            queryParameters['includeAttributes'] = requestParameters.includeAttributes;
        }

        if (requestParameters.paginationToken !== undefined) {
            queryParameters['paginationToken'] = requestParameters.paginationToken;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user_pools/{userPoolId}/users`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUsersResponseFromJSON(jsonValue));
    }

    /**
     */
    async listUsers(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUsersResponse> {
        const response = await this.listUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resendInviteRaw(requestParameters: ResendInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling resendInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user_pools/{userPoolId}/resend_invite`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInviteRequestToJSON(requestParameters.resendInviteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendInvite(requestParameters: ResendInviteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPoolId === null || requestParameters.userPoolId === undefined) {
            throw new runtime.RequiredError('userPoolId','Required parameter requestParameters.userPoolId was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user_pools/{userPoolId}/reset_password`.replace(`{${"userPoolId"}}`, encodeURIComponent(String(requestParameters.userPoolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

}
