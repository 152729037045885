/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PropsWithChildren, useEffect, useState } from "react";
import { createSafeContext } from "react-safe-context";
import ApiClient from "../api/ApiClient";
import { RuntimeConfigContext } from "./ConfigProvider";

type ApiClientContext = {
  apiClient: ApiClient;
};

/** Context to provide the API client to components that will need it */
export const ApiClientContext =
  createSafeContext<ApiClientContext>("ApiClientContext");

export const ApiClientProvider: React.FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [client, setClient] = useState<ApiClient | undefined>(undefined);

  const config = RuntimeConfigContext.useValue();

  useEffect(() => {
    setClient(
      new ApiClient({
        configuration: {
          basePath: config.api.baseUrl,
        },
        clientId: config.cognito.appClientId,
        fetchApi: window.fetch.bind(window),
      })
    );
  }, [config]);

  return (
    <ApiClientContext.Provider value={{ apiClient: client! }}>
      {children}
    </ApiClientContext.Provider>
  );
};
