import { User, UserStatus } from "@tractableai/auth-management-client";

/** Return a display name for the user depending on what attributes it has. */
export function userDisplayName(user: User): string {
  if (user.givenName && user.familyName) {
    return `${user.givenName} ${user.familyName}`;
  } else if (user.email) {
    return user.email;
  }
  return user.username; // This should always be defined
}

export function statusName(status: UserStatus): string {
  switch (status) {
    case "joined":
    case "external_user":
      return "Joined";
    case "pending":
      return "Pending";
    case "expired_invite":
      return "Expired invite";
    case "deactivated":
      return "Deactivated";
    case "reset_required":
      return "Reset required";
    case "unknown":
      return "Unknown";
  }
}
