import {
  Configuration,
  ConfigurationParameters,
  DefaultApi,
  FetchAPI,
  RequestContext,
} from "@tractableai/auth-management-client";

type ApiToken = {
  /** The "raw" ID token, to be sent in the Authorization header */
  rawToken: string;

  /** The expiration of the raw ID token */
  exp: number;

  refreshToken: string;
};

type ApiClientProps = {
  configuration: ConfigurationParameters;
  fetchApi: FetchAPI;
  clientId: string;
};

/** Extended API client that holds the token needed to auth with the API,
 * setting it with a middleware function. */
class ApiClient extends DefaultApi {
  private _token?: ApiToken;

  /** Cognito app client ID */
  clientId: string;

  constructor(props: ApiClientProps) {
    super(
      new Configuration({
        ...props.configuration,
        fetchApi: props.fetchApi,
        middleware: [
          {
            pre: async (context: RequestContext) => {
              // Refresh first (if necessary) so that the request won't fail if
              // the token has expired
              await this.refreshIdTokenIfNecessary(context);
              return Promise.resolve({
                ...context,
                init: {
                  ...context.init,
                  headers: {
                    ...context.init.headers,
                    Authorization: this.token?.rawToken ?? "",
                  },
                },
              });
            },
          },
        ],
      })
    );

    this.clientId = props.clientId;
  }

  /** Checks the expiration time of the currently-used ID token, and if it will
   * expire soon, or has already expired, uses the refresh token to obtain and
   * store a new one. */
  async refreshIdTokenIfNecessary(context: RequestContext) {
    if (
      this.token?.exp &&
      this.token?.refreshToken &&
      // Don't try to refresh when accessing the token exchange route, since
      // the refresh itself uses that route and we'll have an infinite loop
      !context.url.endsWith("authentication/code")
    ) {
      const currentEpochSeconds = Math.round(Date.now() / 1000);
      const expirationEpochSeconds = this.token?.exp;
      if (expirationEpochSeconds - currentEpochSeconds < 60) {
        const refreshToken = this.token?.refreshToken;

        // TODO(kevin): Take out these debug logs eventually
        console.log(
          "Token will expire soon or has already expired. Refreshing..."
        );
        const result = await this.exchangeCode({
          exchangeCodeRequest: {
            refreshToken,
            grantType: "refresh_token",
          },
        });

        this.setToken({
          refreshToken,
          rawToken: result.rawIdToken,
          exp: result.idToken.exp,
        });

        console.log(`Set new raw token to ${result.rawIdToken}`);
      }
    }
  }

  // Compute this dynamically so we always get the latest token for every call
  // even after it gets updated
  get token() {
    return this._token;
  }

  // NOTE: not using a setter here because we don't want to allow setting the
  // token to undefined, which would be required for getter/setter symmetry
  setToken(tokens: ApiToken) {
    this._token = tokens;
  }
}

/* eslint-disable */
/** A way to extract the errorMessage returned by the API in error cases. For
 * some reason the body object doesn't have the json() function that the Fetch
 * API specifies, so as a workaround we temporarily create a new response with
 * the same body and extract it that way. */
export async function getErrorMessage(e: any): Promise<string | undefined> {
  if (e?.response?.body instanceof ReadableStream) {
    const json = await new Response(e.response.body).json();
    return json.errorMessage;
  }
  return undefined;
}
/* eslint-enable */

export default ApiClient;
