import { useTractableTheme } from "@tractable/frame-ui";
import * as React from "react";
import { ThemeProvider } from "react-jss";

// From https://github.com/tractableai/property-estimating/blob/main/frontend/claim-handler/src/JssTheme/FrameToJssThemeProvider.tsx
export const FrameToJssThemeProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const theme = useTractableTheme();

  return <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>;
};
