import { Center, TextInput } from "@mantine/core";
import { SearchIcon } from "@tractable/frame-ui";
import { ChangeEventHandler } from "react";
import { createUseStyles } from "react-jss";

type UserListFiltersProps = {
  query: string;
  setQuery: ChangeEventHandler<HTMLInputElement>;
};

const UserListFilters: React.FC<UserListFiltersProps> = (props) => {
  const classes = useStyles();

  return (
    <Center inline>
      <TextInput
        radius={2}
        aria-label="search"
        className={classes.searchInput}
        placeholder="Search by email address or user ID"
        value={props.query}
        onChange={props.setQuery}
        icon={<SearchIcon />}
      />
    </Center>
  );
};

const useStyles = createUseStyles({
  filters: { marginBottom: "8px" },
  searchInput: { width: "304px", marginRight: "32px" },
});

export default UserListFilters;
