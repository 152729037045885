/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Loader } from "@mantine/core";
import { RuntimeConfig } from "@tractableai/auth-management-api";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { createSafeContext } from "react-safe-context";

/** Context to provide the UI's config object to components that will need it */
export const RuntimeConfigContext = createSafeContext<RuntimeConfig>(
  "RuntimeConfigContext"
);

export const ConfigProvider: React.FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [runtimeContext, setRuntimeContext] = useState<
    RuntimeConfig | undefined
  >(undefined);

  useEffect(() => {
    fetch("/runtime-config.json")
      .then((response) => {
        return response.json() as unknown as RuntimeConfig;
      })
      .then((runtimeCtx) => {
        setRuntimeContext(runtimeCtx);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [setRuntimeContext]);

  const ConfigWrapper: React.FC<{ children: React.ReactNode }> = useCallback(
    ({ children: _children }) => {
      // Don't render anything if the context has not finalized.
      // The whole config is set at once, so we can check any field here.
      // `domain` is chosen arbitrarily.
      return runtimeContext?.cognito.domain ? <>{_children}</> : <Loader />;
    },
    [runtimeContext]
  );

  return (
    <ConfigWrapper>
      <RuntimeConfigContext.Provider value={runtimeContext!}>
        {children}
      </RuntimeConfigContext.Provider>
    </ConfigWrapper>
  );
};
