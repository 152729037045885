/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserPool } from './UserPool';
import {
    UserPoolFromJSON,
    UserPoolFromJSONTyped,
    UserPoolToJSON,
} from './UserPool';

/**
 * 
 * @export
 * @interface ListUserPoolsResponse
 */
export interface ListUserPoolsResponse {
    /**
     * 
     * @type {Array<UserPool>}
     * @memberof ListUserPoolsResponse
     */
    userPools: Array<UserPool>;
}


/**
 * Check if a given object implements the ListUserPoolsResponse interface.
 */
export function instanceOfListUserPoolsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userPools" in value;

    return isInstance;
}

export function ListUserPoolsResponseFromJSON(json: any): ListUserPoolsResponse {
    return ListUserPoolsResponseFromJSONTyped(json, false);
}

export function ListUserPoolsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUserPoolsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userPools': ((json['userPools'] as Array<any>).map(UserPoolFromJSON)),
    };
}

export function ListUserPoolsResponseToJSON(value?: ListUserPoolsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userPools': ((value.userPools as Array<any>).map(UserPoolToJSON)),
    };
}

