import {
  Alert,
  Button,
  Flex,
  Grid,
  Loader,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ExclamationIcon, ITractableTheme } from "@tractable/frame-ui";
import { Colour, colours } from "@tractable/frame-ui/build/theme/colour";
import {
  DescribeUserPoolResponse,
  UserPool,
} from "@tractableai/auth-management-client";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ApiClientContext } from "../context/ApiClientProvider";
import { RuntimeConfigContext } from "../context/ConfigProvider";
import { UserPoolContext } from "../context/UserPoolProvider";
import { AllProducts, getUniqueProductId, Product } from "../products";
import { commonStyles } from "../styles";
import PasswordValidation, {
  calculateNewPasswordValidity,
} from "./PasswordValidation";

type UserAddProps = {
  closeModal: () => void;
  onSuccess: () => Promise<void>;
};

type UserAddForm = {
  userPool: UserPool;
  product: Product;

  /** Comma-separated list of emails */
  emails: string;
  temporaryPassword: string;
};

type Step = "form" | "confirm";

/* These clients have the 'username' signin option enabled, which means our user
   creation route doesn't work for them (see note in create-users.ts). But that
   should be OK since these clients only use SSO. If the user selects one of
   them, show an error message instead of the form. */
const ssoOnlyClients = ["tractable"];

// Allow splitting emails on commas or newlines
const emailSplitRegexp = /[,\n]/;

const UserAdd = (props: UserAddProps) => {
  const { userPools, userPool } = UserPoolContext.useValue();
  const { apiClient } = ApiClientContext.useValue();
  const config = RuntimeConfigContext.useValue();
  const products = AllProducts[config.trEnv];

  if (!products) {
    return (
      <Alert title="No products!" color={colours.red[30]}>
        <div>
          This environment has no products defined, so new users cannot be
          added.
        </div>
      </Alert>
    );
  }
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<Step>("form");
  const [userPoolDetails, setUserPoolDetails] = useState<
    DescribeUserPoolResponse | undefined
  >(undefined);

  useEffect(() => {
    setLoading(true);
    apiClient
      .describeUserPool({
        userPoolId: userPool.userPoolId,
      })
      .then((pool) => {
        setUserPoolDetails(pool);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [apiClient]);

  const form = useForm<UserAddForm>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userPool: userPools.find(
        (pool) => pool.userPoolId === userPool.userPoolId
      )!,
      product: products[0],
      emails: "",
      temporaryPassword: "",
    },
    validate: (values: UserAddForm) => {
      if (values.emails.length === 0) {
        return { emails: "Enter at least one email address" };
      }
      const splitEmails = values.emails.split(emailSplitRegexp);

      // Make sure that all emails at least have a @ symbol
      if (splitEmails.find((email) => !/.@./.test(email))) {
        return { emails: "Invalid email" };
      }

      // Temporary password is optional, so only validate if they enter
      // something
      if (userPoolDetails && values.temporaryPassword.length > 0) {
        const passwordValidity = calculateNewPasswordValidity(
          userPoolDetails.passwordPolicy,
          values.temporaryPassword
        );
        if (Object.values(passwordValidity).includes(false)) {
          return {
            temporaryPassword: "Enter a valid password",
          };
        }
      }
      return {};
    },
  });

  const handleSubmit = async (values: UserAddForm) => {
    switch (step) {
      case "form":
        setStep("confirm");
        return;
      case "confirm":
        setLoading(true);
        try {
          await apiClient.createUsers({
            userPoolId: values.userPool.userPoolId,
            createUsersRequest: {
              emails: values.emails.split(emailSplitRegexp),
              temporaryPassword: values.temporaryPassword || undefined,
              env: config.trEnv,
              productId: values.product.productId,
              productUrl: values.product.homeUrl,
              clientId: values.userPool.clientId,
            },
          });
          props.closeModal();
          await props.onSuccess();
        } catch (e) {
          setLoading(false);
        }

        return;
    }
  };

  const classes = useStyles();

  if (!userPoolDetails) {
    return <Loader />;
  }

  const passwordValidity = calculateNewPasswordValidity(
    userPoolDetails.passwordPolicy,
    form.values.temporaryPassword
  );

  const isSsoOnlyClient = ssoOnlyClients.includes(
    form.values.userPool.clientId
  );

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {step === "form" && (
          <>
            <Select
              label="Client"
              className={classes.spacingBottom}
              value={form.values.userPool.userPoolId}
              onChange={(newPoolId) =>
                form.setFieldValue(
                  "userPool",
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  userPools.find((p) => p.userPoolId === newPoolId)!
                )
              }
              data={userPools.map((pool) => {
                return {
                  value: pool.userPoolId,
                  label: pool.clientId,
                };
              })}
              disabled={loading}
            />
            {isSsoOnlyClient && (
              <Alert icon={<ExclamationIcon colour={Colour.Blue60} />}>
                You cannot add new user accounts for this client because they
                use single sign-on. New users will automatically create their
                accounts by logging in for the first time.
              </Alert>
            )}
            {!isSsoOnlyClient && (
              <>
                <Select
                  label="Product"
                  className={classes.spacingBottom}
                  value={getUniqueProductId(form.values.product)}
                  onChange={(uniqueProductId) => {
                    const matchingProduct = products.find(
                      (p) => getUniqueProductId(p) === uniqueProductId
                    );

                    if (matchingProduct) {
                      form.setFieldValue("product", matchingProduct);
                    } else {
                      console.error(
                        `No matching product for ${
                          uniqueProductId ?? "(none)"
                        } found`
                      );
                    }
                  }}
                  data={products.map((product) => {
                    return {
                      value: getUniqueProductId(product),
                      label: product.uiName,
                    };
                  })}
                  disabled={loading}
                />
                <Textarea
                  minRows={5}
                  label="Email addresses"
                  {...form.getInputProps("emails")}
                  className={classes.emailExplanation}
                  disabled={loading}
                />
                <div className={classes.spacingBottom}>
                  Enter multiple email addresses by (1) copying and pasting from
                  a single spreadsheet column or (2) separating by commas
                </div>
                <TextInput
                  label="Temporary password (optional)"
                  autoComplete="false"
                  {...form.getInputProps("temporaryPassword")}
                  disabled={loading}
                />
                <PasswordValidation
                  validity={passwordValidity}
                  minimumLength={userPoolDetails.passwordPolicy.minimumLength}
                  passwordPresent={form.values.temporaryPassword.length > 0}
                />
                <div className={classes.spacingTop}>
                  This password will apply to all users added
                </div>
              </>
            )}
          </>
        )}
        {step === "confirm" && (
          <div>
            <Grid>
              <Grid.Col span={4}>Client</Grid.Col>
              <Grid.Col span={8}>{form.values.userPool.clientId}</Grid.Col>
              <Grid.Col span={4}>Product</Grid.Col>
              <Grid.Col span={8}>{form.values.product.uiName}</Grid.Col>
              <Grid.Col span={4}>User accounts</Grid.Col>
              <Grid.Col span={8}>
                {form.values.emails.split(emailSplitRegexp).length}
              </Grid.Col>
            </Grid>
          </div>
        )}

        {!isSsoOnlyClient && (
          <Flex className={classes.buttonRow} justify="flex-end">
            <Button
              className={classes.cancelButton}
              variant="subtle"
              type="submit"
              disabled={loading}
              onClick={() => props.closeModal()}
            >
              Cancel
            </Button>
            {step === "confirm" && (
              <Button
                variant="default"
                className={classes.backButton}
                disabled={loading}
                onClick={() => setStep("form")}
              >
                Back
              </Button>
            )}
            <Button
              className={classes.button}
              type="submit"
              disabled={loading}
              loading={loading}
            >
              {step === "form" ? "Next" : "Send invites"}
            </Button>
          </Flex>
        )}
      </form>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  backButton: {
    marginRight: "24px",
    color: theme.colour.Purple60,
  },
  buttonRow: {
    marginTop: "56px",
  },
  spacingBottom: {
    marginBottom: "24px",
  },
  spacingTop: {
    marginTop: "24px",
  },
  emailExplanation: {
    marginTop: "4px",
  },
}));

export default UserAdd;
