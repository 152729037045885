/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ListUsersResponse
 */
export interface ListUsersResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof ListUsersResponse
     */
    users: Array<User>;
    /**
     * 
     * @type {string}
     * @memberof ListUsersResponse
     */
    userPoolId: string;
    /**
     * Token to pass to the list-users route to get the next page of users
     * @type {string}
     * @memberof ListUsersResponse
     */
    paginationToken?: string;
}


/**
 * Check if a given object implements the ListUsersResponse interface.
 */
export function instanceOfListUsersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "userPoolId" in value;

    return isInstance;
}

export function ListUsersResponseFromJSON(json: any): ListUsersResponse {
    return ListUsersResponseFromJSONTyped(json, false);
}

export function ListUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserFromJSON)),
        'userPoolId': json['userPoolId'],
        'paginationToken': !exists(json, 'paginationToken') ? undefined : json['paginationToken'],
    };
}

export function ListUsersResponseToJSON(value?: ListUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(UserToJSON)),
        'userPoolId': value.userPoolId,
        'paginationToken': value.paginationToken,
    };
}

