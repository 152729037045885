import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiClientContext } from "../context/ApiClientProvider";
import { RuntimeConfigContext } from "../context/ConfigProvider";
import { TokensContext } from "../context/TokensProvider";
import {
  LOCAL_STORAGE_AUTH_STATE_KEY,
  LOCAL_STORAGE_CODE_VERIFIER_KEY,
} from "./Auth";
/**
 * Component to render the SAML callback route
 */
const SAMLCallback: React.FC = () => {
  const navigate = useNavigate();
  const query = location.search;
  const params = new URLSearchParams(query);

  const maybeCode = params.get("code");
  const maybeAuthState = params.get("state");
  const config = RuntimeConfigContext.useValue();
  const { apiClient } = ApiClientContext.useValue();
  const { tokens, setTokens } = TokensContext.useValue();

  useEffect(() => {
    const exchange = async (code: string) => {
      const codeChallenge = localStorage.getItem(
        LOCAL_STORAGE_CODE_VERIFIER_KEY
      );
      const result = await apiClient.exchangeCode({
        exchangeCodeRequest: {
          grantType: "authorization_code",
          redirectUri: config.cognito.redirectUrl,
          code,
          codeVerifier: codeChallenge ?? "",
        },
      });

      if (result) {
        setTokens?.({
          accessToken: result.accessToken,
          idToken: result.idToken,
        });
        apiClient.setToken({
          rawToken: result.rawIdToken,

          // We know that sending in a authorization code should result in a
          // refresh token being returned
          refreshToken: result.refreshToken!, // eslint-disable-line
          exp: result.idToken.exp,
        });
      }
    };

    const localState = localStorage.getItem(LOCAL_STORAGE_AUTH_STATE_KEY);

    if (localState !== maybeAuthState) {
      console.error("Auth state does not match");
    } else if (!tokens && maybeCode && apiClient) {
      exchange(maybeCode).catch(console.error);
    }
  }, [setTokens, tokens, apiClient]);

  useEffect(() => {
    if (tokens) {
      navigate("/", { replace: true });
    }
  }, [tokens]);

  return null;
};

export default SAMLCallback;
