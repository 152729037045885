/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * Present for external users
     * @type {string}
     * @memberof User
     */
    familyName?: string;
    /**
     * Present for external users
     * @type {string}
     * @memberof User
     */
    givenName?: string;
    /**
     * Cognito's native "sub" attribute
     * @type {string}
     * @memberof User
     */
    sub?: string;
    /**
     * Custom userId attribute
     * @type {string}
     * @memberof User
     */
    userId?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    status: UserStatus;
    /**
     * Created date in ISO-8601 format
     * @type {string}
     * @memberof User
     */
    createdOn: string;
}


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdOn" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'sub': !exists(json, 'sub') ? undefined : json['sub'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'status': UserStatusFromJSON(json['status']),
        'createdOn': json['createdOn'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'familyName': value.familyName,
        'givenName': value.givenName,
        'sub': value.sub,
        'userId': value.userId,
        'status': UserStatusToJSON(value.status),
        'createdOn': value.createdOn,
    };
}

