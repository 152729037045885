import { AppShell, Flex, Header, Navbar, Text } from "@mantine/core";
import { ITractableTheme, TractableLogo } from "@tractable/frame-ui";
import { PropsWithChildren } from "react";
import { createUseStyles } from "react-jss";
import ClientList from "./ClientList";

const Shell: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();

  return (
    <AppShell
      className={classes.shell}
      padding={8}
      navbar={
        <Navbar width={{ base: 200 }} p={0}>
          <ClientList />
        </Navbar>
      }
      header={
        <Header height={60} p={16}>
          <Flex className={classes.flexContainer} align="center">
            <TractableLogo />
            <Text className={classes.title} fw={700}>
              TRAM
            </Text>
          </Flex>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  shell: {
    backgroundColor: theme.colour.Grey3,
  },
  flexContainer: {
    marginLeft: "24px",
  },
  title: {
    marginLeft: "24px",
  },
}));

export default Shell;
