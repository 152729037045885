import {
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Stack,
  Table,
  Title,
} from "@mantine/core";
import { useDebouncedValue, useInputState } from "@mantine/hooks";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ITractableTheme,
  PlusIcon,
} from "@tractable/frame-ui";
import { Colour } from "@tractable/frame-ui/build/theme/colour";
import { IconSize } from "@tractable/frame-ui/build/theme/size";
import { User, UserPool } from "@tractableai/auth-management-client";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ApiClientContext } from "../context/ApiClientProvider";
import { UserPoolContext } from "../context/UserPoolProvider";
import { commonStyles } from "../styles";
import UserAdd from "./UserAdd";
import UserListFilters from "./UserListFilters";
import UserRow from "./UserRow";

type UserListProps = Record<string, never>;

type UserPage = {
  users: User[];
  nextPaginationToken?: string;
};

const UserList: React.FC<UserListProps> = (_props) => {
  const { userPool } = UserPoolContext.useValue();
  const { apiClient } = ApiClientContext.useValue();

  // All pages of users seen so far
  const [userPages, setUserPages] = useState<UserPage[]>([]);

  // The page currently being displayed
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [addModalOpened, setAddModalOpened] = useState(false);

  // Filters
  const [query, setQuery] = useInputState("");
  const [debouncedQuery] = useDebouncedValue(query, 300);

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchUsers = async (args: {
    pool: UserPool;
    paginationToken?: string;
  }) => {
    const { pool, paginationToken } = args;
    setLoading(true);

    const result = await apiClient.listUsers(
      {
        userPoolId: pool.userPoolId,
        search: debouncedQuery,
        paginationToken,
        pageSize: 60,
      },
      {
        signal,
      }
    );

    setLoading(false);

    const filteredUsers = result.users;
    // Sort by first names alphabetically, with users without names coming at
    // the end
    filteredUsers.sort((a, b) => {
      if (a.givenName && b.givenName) {
        return a.givenName.localeCompare(b.givenName);
      } else if (a.givenName) {
        // A has name, B doesn't: A comes first
        return -1;
      } else if (b.givenName) {
        // B has name, A doesn't: B comes first
        return 1;
      } else {
        return a.email.localeCompare(b.email);
      }
    });

    // Only set the user list if the API response has a user pool ID that
    // matches the user pool that's currently selected. This is to prevent a
    // response for a previously-selected user pool erroneously being used to
    // display users for a different user pool.
    if (result.userPoolId === userPool.userPoolId) {
      setUserPages((pages) => [
        ...pages,
        {
          users: filteredUsers,
          nextPaginationToken: result.paginationToken,
        },
      ]);
    }
  };

  // When the search query or client is changed, reset the user list and fetch
  // users from the API
  useEffect(() => {
    if (userPool) {
      setUserPages([]);
      setCurrentPageIndex(0);
      fetchUsers({ pool: userPool }).catch(console.error);
    }
  }, [userPool, debouncedQuery]);

  const handleAddUsers = () => {
    setAddModalOpened(true);
  };

  const currentPage = userPages.at(currentPageIndex);

  const canGoBack = currentPageIndex > 0;

  const canGoForward =
    currentPageIndex < userPages.length - 1 ||
    !!currentPage?.nextPaginationToken;

  const handleBack = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex((i) => i - 1);
    } else {
      console.error("Tried to view a negative page index");
    }
  };

  const handleNext = async () => {
    if (currentPageIndex < userPages.length - 1) {
      setCurrentPageIndex((i) => i + 1);
    } else {
      const nextPaginationToken = currentPage?.nextPaginationToken;
      if (nextPaginationToken) {
        await fetchUsers({
          pool: userPool,
          paginationToken: nextPaginationToken,
        });
        setCurrentPageIndex((i) => i + 1);
      }
    }
  };

  const resetAndFetchUsers = async () => {
    setUserPages([]);
    setCurrentPageIndex(0);
    await fetchUsers({ pool: userPool }).catch(console.error);
  };

  const classes = useStyles();

  return (
    <>
      <Modal
        opened={addModalOpened}
        onClose={() => setAddModalOpened(false)}
        title={<Title order={3}>Add new users</Title>}
        withCloseButton={false}
      >
        <UserAdd
          closeModal={() => setAddModalOpened(false)}
          onSuccess={resetAndFetchUsers}
        />
      </Modal>

      <Stack align="flex-start">
        <div className={classes.loadingContainer}>
          <Flex justify="space-between" className={classes.flexRow}>
            <UserListFilters query={query} setQuery={setQuery} />
            <Button
              className={classes.button}
              leftIcon={<PlusIcon colour={Colour.White} />}
              onClick={handleAddUsers}
            >
              Add new users
            </Button>
          </Flex>
          <LoadingOverlay visible={loading} overlayBlur={2} />
          {currentPage && (
            <Table role="table" className={classes.table}>
              <thead className={classes.tableHeader}>
                <tr>
                  <th className={classes.nameColumn}>Name</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentPage.users.map((user) => (
                  <UserRow
                    user={user}
                    clientId={userPool.clientId}
                    key={user.sub}
                    onDeletion={resetAndFetchUsers}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th />
                  <th />
                  <th className={classes.footer}>
                    <Button
                      className={classes.paginationButton}
                      variant="subtle"
                      disabled={!canGoBack}
                      onClick={handleBack}
                    >
                      <ChevronLeftIcon
                        size={IconSize.SMALL}
                        colour={canGoBack ? Colour.Black : Colour.Grey20}
                      />
                      Back
                    </Button>
                    <Button
                      className={classes.paginationButton}
                      variant="subtle"
                      disabled={!canGoForward}
                      onClick={handleNext}
                    >
                      Next
                      <ChevronRightIcon
                        size={IconSize.SMALL}
                        colour={canGoForward ? Colour.Black : Colour.Grey20}
                      />
                    </Button>
                  </th>
                </tr>
              </tfoot>
            </Table>
          )}
        </div>
      </Stack>
    </>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  table: { backgroundColor: theme.colour.PageBackgroundPrimary },
  tableHeader: { backgroundColor: theme.colour.Grey3 },
  alert: { backgroundColor: theme.colour.Cyan5 },
  filters: { marginBottom: "8px" },
  flexRow: { width: "100%" },
  footer: { display: "flex", justifyContent: "flex-end" },
  paginationButton: {
    color: theme.colour.Black,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: theme.colour.Purple10,
    },
    "&:disabled": {
      backgroundColor: theme.colour.White,
    },
  },
  loadingContainer: {
    // Necessary for LoadingOverlay to work
    position: "relative",

    width: "100%",
    height: "100%",
  },
  nameColumn: {
    width: "50%",
  },
}));

export default UserList;
