/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Auth Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPool
 */
export interface UserPool {
    /**
     * 
     * @type {string}
     * @memberof UserPool
     */
    userPoolId: string;
    /**
     * 
     * @type {string}
     * @memberof UserPool
     */
    clientId: string;
}


/**
 * Check if a given object implements the UserPool interface.
 */
export function instanceOfUserPool(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userPoolId" in value;
    isInstance = isInstance && "clientId" in value;

    return isInstance;
}

export function UserPoolFromJSON(json: any): UserPool {
    return UserPoolFromJSONTyped(json, false);
}

export function UserPoolFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPool {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userPoolId': json['userPoolId'],
        'clientId': json['clientId'],
    };
}

export function UserPoolToJSON(value?: UserPool | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userPoolId': value.userPoolId,
        'clientId': value.clientId,
    };
}

