import { Badge, Modal, Title, Text, Flex } from "@mantine/core";
import { useFocusWithin } from "@mantine/hooks";
import { ITractableTheme } from "@tractable/frame-ui";
import { User } from "@tractableai/auth-management-client";
import classNames from "classnames";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import { statusName } from "../i18n";
import UserActions from "./UserActions";
import UserDetails from "./UserDetails";

type UserRowProps = {
  user: User;
  clientId: string;
  onDeletion: () => Promise<void>;
};

const UserRow = (props: UserRowProps) => {
  const { user, clientId, onDeletion } = props;
  const [detailsModalOpened, setDetailsModalOpened] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setDetailsModalOpened(true);
  };

  // The row will be "active" (background color changed to purple and action
  // button visible) when either the user is hovering over the row, or one of
  // its child elements has focus, i.e. the user has clicked this row's action
  // button
  const { ref, focused } = useFocusWithin();

  // Display full name if we have it, or just email otherwise
  let username = user.email;
  let nameDisplay = <div data-testid="user-email">{user.email}</div>;
  if (user.familyName || user.givenName) {
    // Handle the possibility of only family name, or only given name
    if (user.familyName && user.givenName) {
      username = `${user.givenName} ${user.familyName}`;
    } else if (user.familyName) {
      username = user.familyName;
    } else if (user.givenName) {
      username = user.givenName;
    }
    nameDisplay = (
      <div>
        <Text className={classes.mainName}>{username}</Text>
        <Text data-testid="user-email" className={classes.subName}>
          {user.email}
        </Text>
      </div>
    );
  }
  return (
    <>
      <Modal
        onClose={() => setDetailsModalOpened(false)}
        opened={detailsModalOpened}
        title={
          <Title className={classes.modalTitle} order={4}>
            {username}
          </Title>
        }
        className={classes.modal}
      >
        <UserDetails user={user} clientId={clientId} />
      </Modal>
      <tr
        className={classNames(classes.userRow, { childHasFocus: focused })}
        aria-label="user-row"
        ref={ref}
      >
        <td className={classes.detailsButton} onClick={handleClick}>
          {nameDisplay}
        </td>
        <td className={classes.detailsButton} onClick={handleClick}>
          <Badge
            size="lg"
            className={classNames(classes.status, `status-${user.status}`)}
          >
            {statusName(user.status)}
          </Badge>
        </td>
        <td>
          <Flex justify="flex-end">
            <UserActions
              className={classes.userActions}
              user={user}
              onDeletion={onDeletion}
            />
          </Flex>
        </td>
      </tr>
    </>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  userRow: {
    "&:hover, &.childHasFocus": {
      backgroundColor: theme.colour.Purple5,
      "& $userActions": {
        visibility: "visible",
      },
    },
  },
  detailsButton: {
    cursor: "pointer",
  },
  modal: {
    "& .mantine-Modal-modal": {
      width: "auto",
    },
    "& .mantine-Modal-header": {
      marginBottom: 0,
    },
  },
  modalTitle: {
    marginLeft: "32px",
  },
  userActions: {
    visibility: "hidden",
  },
  status: {
    textTransform: "none",
    color: theme.colour.TextPrimary,
    fontWeight: "normal",
    "&.status-pending": {
      backgroundColor: theme.colour.Grey5,
    },
    "&.status-expired_invite, &.status-reset_required": {
      backgroundColor: theme.colour.Red10,
    },
    "&.status-joined, &.status-deactivated, &.status-unknown, &.status-external_user":
      {
        backgroundColor: "transparent",
      },
  },
  mainName: {
    fontSize: "14px",
  },
  subName: {
    color: theme.colour.TextTertiary,
    fontSize: "12px",
  },
}));

export default UserRow;
