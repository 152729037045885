import { IconProps } from "./IconProps";

const ResendIcon = (props: IconProps) => {
  return (
    <svg
      className={props.className}
      width="24px"
      height="24px"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99984 2.99992V0.333252L2.6665 3.66659L5.99984 6.99992V4.33325C8.2065 4.33325 9.99984 6.12659 9.99984 8.33325C9.99984 10.5399 8.2065 12.3333 5.99984 12.3333C3.79317 12.3333 1.99984 10.5399 1.99984 8.33325H0.666504C0.666504 11.2799 3.05317 13.6666 5.99984 13.6666C8.9465 13.6666 11.3332 11.2799 11.3332 8.33325C11.3332 5.38659 8.9465 2.99992 5.99984 2.99992Z"
        fill={props.colour}
      />
    </svg>
  );
};

export default ResendIcon;
