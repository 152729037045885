import { IconProps } from "./IconProps";

const Ellipse = (props: IconProps) => {
  return (
    <svg
      className={props.className}
      data-testid="rule-invalid"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill={props.colour} />
    </svg>
  );
};

export default Ellipse;
