import { IconProps } from "./IconProps";

const MailIcon = (props: IconProps) => {
  return (
    <svg
      className={props.className}
      width="24px"
      height="24px"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 11.3334C1.06683 11.3334 0.833496 11.2334 0.633496 11.0334C0.433496 10.8334 0.333496 10.6001 0.333496 10.3334V1.66675C0.333496 1.40008 0.433496 1.16675 0.633496 0.966748C0.833496 0.766748 1.06683 0.666748 1.3335 0.666748H12.6668C12.9335 0.666748 13.1668 0.766748 13.3668 0.966748C13.5668 1.16675 13.6668 1.40008 13.6668 1.66675V10.3334C13.6668 10.6001 13.5668 10.8334 13.3668 11.0334C13.1668 11.2334 12.9335 11.3334 12.6668 11.3334H1.3335ZM7.00016 6.30008L1.3335 2.58341V10.3334H12.6668V2.58341L7.00016 6.30008ZM7.00016 5.30008L12.6002 1.66675H1.41683L7.00016 5.30008ZM1.3335 2.58341V1.66675V10.3334V2.58341Z"
        fill={props.colour}
      />
    </svg>
  );
};

export default MailIcon;
