// Import models
import {
    ApiErrorResponseContent,
    ApiErrorResponseContentFromJSON,
    ApiErrorResponseContentToJSON,
    CreateUsersRequest,
    CreateUsersRequestFromJSON,
    CreateUsersRequestToJSON,
    DeleteUserRequest,
    DeleteUserRequestFromJSON,
    DeleteUserRequestToJSON,
    DescribeUserPoolResponse,
    DescribeUserPoolResponseFromJSON,
    DescribeUserPoolResponseToJSON,
    ExchangeCodeRequest,
    ExchangeCodeRequestFromJSON,
    ExchangeCodeRequestToJSON,
    ExchangeCodeResponse,
    ExchangeCodeResponseFromJSON,
    ExchangeCodeResponseToJSON,
    IdToken,
    IdTokenFromJSON,
    IdTokenToJSON,
    ListUserPoolsResponse,
    ListUserPoolsResponseFromJSON,
    ListUserPoolsResponseToJSON,
    ListUsersResponse,
    ListUsersResponseFromJSON,
    ListUsersResponseToJSON,
    PasswordPolicy,
    PasswordPolicyFromJSON,
    PasswordPolicyToJSON,
    ResendInviteRequest,
    ResendInviteRequestFromJSON,
    ResendInviteRequestToJSON,
    ResetPasswordRequest,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserPool,
    UserPoolFromJSON,
    UserPoolToJSON,
    UserStatus,
    UserStatusFromJSON,
    UserStatusToJSON,
} from '../../models';
// Import request parameter interfaces
import {
    CreateUsersOperationRequest,
    DeleteUserOperationRequest,
    DescribeUserPoolRequest,
    ExchangeCodeOperationRequest,
    
    ListUsersRequest,
    ResendInviteOperationRequest,
    ResetPasswordOperationRequest,
} from '..';

// API Gateway Types
import { APIGatewayProxyEvent, APIGatewayProxyResult, Context } from "aws-lambda";

// Generic type for object keyed by operation names
export interface OperationConfig<T> {
    createUsers: T;
    deleteUser: T;
    describeUserPool: T;
    exchangeCode: T;
    listUserPools: T;
    listUsers: T;
    resendInvite: T;
    resetPassword: T;
}

// Look up path and http method for a given operation name
export const OperationLookup = {
    createUsers: {
        path: '/user_pools/{userPoolId}/users',
        method: 'POST',
    },
    deleteUser: {
        path: '/user_pools/{userPoolId}/users',
        method: 'DELETE',
    },
    describeUserPool: {
        path: '/user_pool/{userPoolId}',
        method: 'GET',
    },
    exchangeCode: {
        path: '/authentication/code',
        method: 'POST',
    },
    listUserPools: {
        path: '/user_pools',
        method: 'GET',
    },
    listUsers: {
        path: '/user_pools/{userPoolId}/users',
        method: 'GET',
    },
    resendInvite: {
        path: '/user_pools/{userPoolId}/resend_invite',
        method: 'POST',
    },
    resetPassword: {
        path: '/user_pools/{userPoolId}/reset_password',
        method: 'POST',
    },
};

export class Operations {
  /**
   * Return an OperationConfig with the same value for every operation
   */
  public static all = <T>(value: T): OperationConfig<T> => Object.fromEntries(
    Object.keys(OperationLookup).map((operationId) => [operationId, value])
  ) as unknown as OperationConfig<T>;
}

// Standard apigateway request parameters (query parameters or path parameters, multi or single value)
type ApiGatewayRequestParameters = { [key: string]: string | string[] | undefined };

/**
 * URI decode for a string or array of strings
 */
const uriDecode = (value: string | string[]): string | string[] =>
    typeof value === 'string' ? decodeURIComponent(value) : value.map((v) => decodeURIComponent(v));

/**
 * URI decodes apigateway request parameters (query or path parameters)
 */
const decodeRequestParameters = (parameters: ApiGatewayRequestParameters): ApiGatewayRequestParameters => {
    const decodedParameters = {};
    Object.keys(parameters || {}).forEach((key) => {
        decodedParameters[key] = parameters[key] ? uriDecode(parameters[key]) : parameters[key];
    });
    return decodedParameters;
};

/**
 * Parse the body if the content type is json, otherwise leave as a raw string
 */
const parseBody = (body: string, demarshal: (body: string) => any, contentTypes: string[]): any => contentTypes.filter((contentType) => contentType !== 'application/json').length === 0 ? demarshal(body || '{}') : body;

type OperationIds = | 'createUsers' | 'deleteUser' | 'describeUserPool' | 'exchangeCode' | 'listUserPools' | 'listUsers' | 'resendInvite' | 'resetPassword';
type OperationApiGatewayProxyResult<T extends OperationIds> = APIGatewayProxyResult & { __operationId?: T };

// Api gateway lambda handler type
type OperationApiGatewayLambdaHandler<T extends OperationIds> = (event: APIGatewayProxyEvent, context: Context) => Promise<OperationApiGatewayProxyResult<T>>;

// Type of the response to be returned by an operation lambda handler
export interface OperationResponse<StatusCode extends number, Body> {
    statusCode: StatusCode;
    headers?: { [key: string]: string };
    body: Body;
}

// Input for a lambda handler for an operation
export type LambdaRequestParameters<RequestParameters, RequestArrayParameters, RequestBody> = {
    requestParameters: RequestParameters,
    requestArrayParameters: RequestArrayParameters,
    body: RequestBody,
};

export type InterceptorContext = { [key: string]: any };

export interface RequestInput<RequestParameters, RequestArrayParameters, RequestBody> {
    input: LambdaRequestParameters<RequestParameters, RequestArrayParameters, RequestBody>;
    event: APIGatewayProxyEvent;
    context: Context;
    interceptorContext: InterceptorContext;
}

export interface ChainedRequestInput<RequestParameters, RequestArrayParameters, RequestBody, Response> extends RequestInput<RequestParameters, RequestArrayParameters, RequestBody> {
    chain: LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response>;
}

/**
 * A lambda handler function which is part of a chain. It may invoke the remainder of the chain via the given chain input
 */
export type ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response> = (
  input: ChainedRequestInput<RequestParameters, RequestArrayParameters, RequestBody, Response>,
) => Promise<Response>;

// Type for a lambda handler function to be wrapped
export type LambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response> = (
  input: RequestInput<RequestParameters, RequestArrayParameters, RequestBody>,
) => Promise<Response>;

export interface LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response> {
  next: LambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>;
}

// Interceptor is a type alias for ChainedLambdaHandlerFunction
export type Interceptor<RequestParameters, RequestArrayParameters, RequestBody, Response> = ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>;

/**
 * Build a chain from the given array of chained lambda handlers
 */
const buildHandlerChain = <RequestParameters, RequestArrayParameters, RequestBody, Response>(
  ...handlers: ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>[]
): LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response> => {
  if (handlers.length === 0) {
    return {
      next: () => {
        throw new Error("No more handlers remain in the chain! The last handler should not call next.");
      }
    };
  }
  const [currentHandler, ...remainingHandlers] = handlers;
  return {
    next: (input) => {
      return currentHandler({
        ...input,
        chain: buildHandlerChain(...remainingHandlers),
      });
    },
  };
};

/**
 * Single-value path/query parameters for CreateUsersOperation
 */
export interface CreateUsersOperationRequestParameters {
    readonly userPoolId: string;
}

/**
 * Multi-value query parameters for CreateUsersOperation
 */
export interface CreateUsersOperationRequestArrayParameters {
}

/**
 * Request body parameter for CreateUsersOperation
 */
export type CreateUsersOperationRequestBody = CreateUsersRequest;

export type CreateUsersOperation201OperationResponse = OperationResponse<201, undefined>;
export type CreateUsersOperation400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type CreateUsersOperation403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type CreateUsersOperation500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type CreateUsersOperationOperationResponses = | CreateUsersOperation201OperationResponse | CreateUsersOperation400OperationResponse | CreateUsersOperation403OperationResponse | CreateUsersOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type CreateUsersOperationHandlerFunction = LambdaHandlerFunction<CreateUsersOperationRequestParameters, CreateUsersOperationRequestArrayParameters, CreateUsersOperationRequestBody, CreateUsersOperationOperationResponses>;
export type CreateUsersOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<CreateUsersOperationRequestParameters, CreateUsersOperationRequestArrayParameters, CreateUsersOperationRequestBody, CreateUsersOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of createUsers
 */
export const createUsersHandler = (
    firstHandler: CreateUsersOperationChainedHandlerFunction,
    ...remainingHandlers: CreateUsersOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'createUsers'> => async (event: any, context: any, _callback?: any, additionalInterceptors: CreateUsersOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as CreateUsersOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as CreateUsersOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return CreateUsersRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as CreateUsersOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 201:
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for DeleteUserOperation
 */
export interface DeleteUserOperationRequestParameters {
    readonly userPoolId: string;
}

/**
 * Multi-value query parameters for DeleteUserOperation
 */
export interface DeleteUserOperationRequestArrayParameters {
}

/**
 * Request body parameter for DeleteUserOperation
 */
export type DeleteUserOperationRequestBody = DeleteUserRequest;

export type DeleteUserOperation204OperationResponse = OperationResponse<204, undefined>;
export type DeleteUserOperation400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type DeleteUserOperation403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type DeleteUserOperation500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type DeleteUserOperationOperationResponses = | DeleteUserOperation204OperationResponse | DeleteUserOperation400OperationResponse | DeleteUserOperation403OperationResponse | DeleteUserOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type DeleteUserOperationHandlerFunction = LambdaHandlerFunction<DeleteUserOperationRequestParameters, DeleteUserOperationRequestArrayParameters, DeleteUserOperationRequestBody, DeleteUserOperationOperationResponses>;
export type DeleteUserOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<DeleteUserOperationRequestParameters, DeleteUserOperationRequestArrayParameters, DeleteUserOperationRequestBody, DeleteUserOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of deleteUser
 */
export const deleteUserHandler = (
    firstHandler: DeleteUserOperationChainedHandlerFunction,
    ...remainingHandlers: DeleteUserOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'deleteUser'> => async (event: any, context: any, _callback?: any, additionalInterceptors: DeleteUserOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as DeleteUserOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as DeleteUserOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return DeleteUserRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as DeleteUserOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 204:
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for DescribeUserPool
 */
export interface DescribeUserPoolRequestParameters {
    readonly userPoolId: string;
}

/**
 * Multi-value query parameters for DescribeUserPool
 */
export interface DescribeUserPoolRequestArrayParameters {
}

/**
 * Request body parameter for DescribeUserPool
 */
export type DescribeUserPoolRequestBody = never;

export type DescribeUserPool200OperationResponse = OperationResponse<200, DescribeUserPoolResponse>;
export type DescribeUserPool400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type DescribeUserPoolOperationResponses = | DescribeUserPool200OperationResponse | DescribeUserPool400OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type DescribeUserPoolHandlerFunction = LambdaHandlerFunction<DescribeUserPoolRequestParameters, DescribeUserPoolRequestArrayParameters, DescribeUserPoolRequestBody, DescribeUserPoolOperationResponses>;
export type DescribeUserPoolChainedHandlerFunction = ChainedLambdaHandlerFunction<DescribeUserPoolRequestParameters, DescribeUserPoolRequestArrayParameters, DescribeUserPoolRequestBody, DescribeUserPoolOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of describeUserPool
 */
export const describeUserPoolHandler = (
    firstHandler: DescribeUserPoolChainedHandlerFunction,
    ...remainingHandlers: DescribeUserPoolChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'describeUserPool'> => async (event: any, context: any, _callback?: any, additionalInterceptors: DescribeUserPoolChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as DescribeUserPoolRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as DescribeUserPoolRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as DescribeUserPoolRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(DescribeUserPoolResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ExchangeCodeOperation
 */
export interface ExchangeCodeOperationRequestParameters {
}

/**
 * Multi-value query parameters for ExchangeCodeOperation
 */
export interface ExchangeCodeOperationRequestArrayParameters {
}

/**
 * Request body parameter for ExchangeCodeOperation
 */
export type ExchangeCodeOperationRequestBody = ExchangeCodeRequest;

export type ExchangeCodeOperation200OperationResponse = OperationResponse<200, ExchangeCodeResponse>;
export type ExchangeCodeOperation400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type ExchangeCodeOperation403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type ExchangeCodeOperation500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type ExchangeCodeOperationOperationResponses = | ExchangeCodeOperation200OperationResponse | ExchangeCodeOperation400OperationResponse | ExchangeCodeOperation403OperationResponse | ExchangeCodeOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ExchangeCodeOperationHandlerFunction = LambdaHandlerFunction<ExchangeCodeOperationRequestParameters, ExchangeCodeOperationRequestArrayParameters, ExchangeCodeOperationRequestBody, ExchangeCodeOperationOperationResponses>;
export type ExchangeCodeOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ExchangeCodeOperationRequestParameters, ExchangeCodeOperationRequestArrayParameters, ExchangeCodeOperationRequestBody, ExchangeCodeOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of exchangeCode
 */
export const exchangeCodeHandler = (
    firstHandler: ExchangeCodeOperationChainedHandlerFunction,
    ...remainingHandlers: ExchangeCodeOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'exchangeCode'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ExchangeCodeOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ExchangeCodeOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ExchangeCodeOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ExchangeCodeRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ExchangeCodeOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(ExchangeCodeResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ListUserPools
 */
export interface ListUserPoolsRequestParameters {
}

/**
 * Multi-value query parameters for ListUserPools
 */
export interface ListUserPoolsRequestArrayParameters {
}

/**
 * Request body parameter for ListUserPools
 */
export type ListUserPoolsRequestBody = never;

export type ListUserPools200OperationResponse = OperationResponse<200, ListUserPoolsResponse>;
export type ListUserPools400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type ListUserPoolsOperationResponses = | ListUserPools200OperationResponse | ListUserPools400OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ListUserPoolsHandlerFunction = LambdaHandlerFunction<ListUserPoolsRequestParameters, ListUserPoolsRequestArrayParameters, ListUserPoolsRequestBody, ListUserPoolsOperationResponses>;
export type ListUserPoolsChainedHandlerFunction = ChainedLambdaHandlerFunction<ListUserPoolsRequestParameters, ListUserPoolsRequestArrayParameters, ListUserPoolsRequestBody, ListUserPoolsOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of listUserPools
 */
export const listUserPoolsHandler = (
    firstHandler: ListUserPoolsChainedHandlerFunction,
    ...remainingHandlers: ListUserPoolsChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'listUserPools'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ListUserPoolsChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ListUserPoolsRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ListUserPoolsRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as ListUserPoolsRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(ListUserPoolsResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ListUsers
 */
export interface ListUsersRequestParameters {
    readonly userPoolId: string;
    readonly search?: string;
    readonly includeAttributes?: string;
    readonly paginationToken?: string;
    readonly pageSize?: string;
}

/**
 * Multi-value query parameters for ListUsers
 */
export interface ListUsersRequestArrayParameters {
}

/**
 * Request body parameter for ListUsers
 */
export type ListUsersRequestBody = never;

export type ListUsers200OperationResponse = OperationResponse<200, ListUsersResponse>;
export type ListUsers400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type ListUsers403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type ListUsers500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type ListUsersOperationResponses = | ListUsers200OperationResponse | ListUsers400OperationResponse | ListUsers403OperationResponse | ListUsers500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ListUsersHandlerFunction = LambdaHandlerFunction<ListUsersRequestParameters, ListUsersRequestArrayParameters, ListUsersRequestBody, ListUsersOperationResponses>;
export type ListUsersChainedHandlerFunction = ChainedLambdaHandlerFunction<ListUsersRequestParameters, ListUsersRequestArrayParameters, ListUsersRequestBody, ListUsersOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of listUsers
 */
export const listUsersHandler = (
    firstHandler: ListUsersChainedHandlerFunction,
    ...remainingHandlers: ListUsersChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'listUsers'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ListUsersChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ListUsersRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ListUsersRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as ListUsersRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(ListUsersResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ResendInviteOperation
 */
export interface ResendInviteOperationRequestParameters {
    readonly userPoolId: string;
}

/**
 * Multi-value query parameters for ResendInviteOperation
 */
export interface ResendInviteOperationRequestArrayParameters {
}

/**
 * Request body parameter for ResendInviteOperation
 */
export type ResendInviteOperationRequestBody = ResendInviteRequest;

export type ResendInviteOperation200OperationResponse = OperationResponse<200, undefined>;
export type ResendInviteOperation400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type ResendInviteOperation403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type ResendInviteOperation500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type ResendInviteOperationOperationResponses = | ResendInviteOperation200OperationResponse | ResendInviteOperation400OperationResponse | ResendInviteOperation403OperationResponse | ResendInviteOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ResendInviteOperationHandlerFunction = LambdaHandlerFunction<ResendInviteOperationRequestParameters, ResendInviteOperationRequestArrayParameters, ResendInviteOperationRequestBody, ResendInviteOperationOperationResponses>;
export type ResendInviteOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ResendInviteOperationRequestParameters, ResendInviteOperationRequestArrayParameters, ResendInviteOperationRequestBody, ResendInviteOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of resendInvite
 */
export const resendInviteHandler = (
    firstHandler: ResendInviteOperationChainedHandlerFunction,
    ...remainingHandlers: ResendInviteOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'resendInvite'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ResendInviteOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ResendInviteOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ResendInviteOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ResendInviteRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ResendInviteOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ResetPasswordOperation
 */
export interface ResetPasswordOperationRequestParameters {
    readonly userPoolId: string;
}

/**
 * Multi-value query parameters for ResetPasswordOperation
 */
export interface ResetPasswordOperationRequestArrayParameters {
}

/**
 * Request body parameter for ResetPasswordOperation
 */
export type ResetPasswordOperationRequestBody = ResetPasswordRequest;

export type ResetPasswordOperation200OperationResponse = OperationResponse<200, undefined>;
export type ResetPasswordOperation400OperationResponse = OperationResponse<400, ApiErrorResponseContent>;
export type ResetPasswordOperation403OperationResponse = OperationResponse<403, ApiErrorResponseContent>;
export type ResetPasswordOperation500OperationResponse = OperationResponse<500, ApiErrorResponseContent>;
export type ResetPasswordOperationOperationResponses = | ResetPasswordOperation200OperationResponse | ResetPasswordOperation400OperationResponse | ResetPasswordOperation403OperationResponse | ResetPasswordOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ResetPasswordOperationHandlerFunction = LambdaHandlerFunction<ResetPasswordOperationRequestParameters, ResetPasswordOperationRequestArrayParameters, ResetPasswordOperationRequestBody, ResetPasswordOperationOperationResponses>;
export type ResetPasswordOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ResetPasswordOperationRequestParameters, ResetPasswordOperationRequestArrayParameters, ResetPasswordOperationRequestBody, ResetPasswordOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of resetPassword
 */
export const resetPasswordHandler = (
    firstHandler: ResetPasswordOperationChainedHandlerFunction,
    ...remainingHandlers: ResetPasswordOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'resetPassword'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ResetPasswordOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ResetPasswordOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ResetPasswordOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ResetPasswordRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ResetPasswordOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(ApiErrorResponseContentToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};

export interface HandlerRouterHandlers {
  readonly createUsers: OperationApiGatewayLambdaHandler<'createUsers'>;
  readonly deleteUser: OperationApiGatewayLambdaHandler<'deleteUser'>;
  readonly describeUserPool: OperationApiGatewayLambdaHandler<'describeUserPool'>;
  readonly exchangeCode: OperationApiGatewayLambdaHandler<'exchangeCode'>;
  readonly listUserPools: OperationApiGatewayLambdaHandler<'listUserPools'>;
  readonly listUsers: OperationApiGatewayLambdaHandler<'listUsers'>;
  readonly resendInvite: OperationApiGatewayLambdaHandler<'resendInvite'>;
  readonly resetPassword: OperationApiGatewayLambdaHandler<'resetPassword'>;
}

export type AnyOperationRequestParameters = | CreateUsersOperationRequestParameters| DeleteUserOperationRequestParameters| DescribeUserPoolRequestParameters| ExchangeCodeOperationRequestParameters| ListUserPoolsRequestParameters| ListUsersRequestParameters| ResendInviteOperationRequestParameters| ResetPasswordOperationRequestParameters;
export type AnyOperationRequestArrayParameters = | CreateUsersOperationRequestArrayParameters| DeleteUserOperationRequestArrayParameters| DescribeUserPoolRequestArrayParameters| ExchangeCodeOperationRequestArrayParameters| ListUserPoolsRequestArrayParameters| ListUsersRequestArrayParameters| ResendInviteOperationRequestArrayParameters| ResetPasswordOperationRequestArrayParameters;
export type AnyOperationRequestBodies = | CreateUsersOperationRequestBody| DeleteUserOperationRequestBody| DescribeUserPoolRequestBody| ExchangeCodeOperationRequestBody| ListUserPoolsRequestBody| ListUsersRequestBody| ResendInviteOperationRequestBody| ResetPasswordOperationRequestBody;
export type AnyOperationResponses = | CreateUsersOperationOperationResponses| DeleteUserOperationOperationResponses| DescribeUserPoolOperationResponses| ExchangeCodeOperationOperationResponses| ListUserPoolsOperationResponses| ListUsersOperationResponses| ResendInviteOperationOperationResponses| ResetPasswordOperationOperationResponses;

export interface HandlerRouterProps<
  RequestParameters,
  RequestArrayParameters,
  RequestBody,
  Response extends AnyOperationResponses
> {
  /**
   * Interceptors to apply to all handlers
   */
  readonly interceptors?: ChainedLambdaHandlerFunction<
    RequestParameters,
    RequestArrayParameters,
    RequestBody,
    Response
  >[];

  /**
   * Handlers to register for each operation
   */
  readonly handlers: HandlerRouterHandlers;
}

const concatMethodAndPath = (method: string, path: string) => `${method.toLowerCase()}||${path}`;

const OperationIdByMethodAndPath = Object.fromEntries(Object.entries(OperationLookup).map(
  ([operationId, methodAndPath]) => [concatMethodAndPath(methodAndPath.method, methodAndPath.path), operationId]
));

/**
 * Returns a lambda handler which can be used to route requests to the appropriate typed lambda handler function.
 */
export const handlerRouter = (props: HandlerRouterProps<
  AnyOperationRequestParameters,
  AnyOperationRequestArrayParameters,
  AnyOperationRequestBodies,
  AnyOperationResponses
>): OperationApiGatewayLambdaHandler<OperationIds> => async (event, context) => {
  const operationId = OperationIdByMethodAndPath[concatMethodAndPath(event.requestContext.httpMethod, event.requestContext.resourcePath)];
  const handler = props.handlers[operationId];
  return handler(event, context, undefined, props.interceptors);
};
