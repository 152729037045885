import { Loader } from "@mantine/core";
import { RuntimeConfig } from "@tractableai/auth-management-api";
import { UserPool } from "@tractableai/auth-management-client";
import { PropsWithChildren, useEffect, useState } from "react";
import { createSafeContext } from "react-safe-context";
import { ApiClientContext } from "./ApiClientProvider";
import { RuntimeConfigContext } from "./ConfigProvider";

type UserPoolContext = {
  /** The selected user pool */
  userPool: UserPool;
  setUserPool: (pool: UserPool) => void;

  /** All available user pools */
  userPools: Array<UserPool>;
};

/** For storing the most recently viewed user pool, so we can restore it the
 * next time the user loads the app. Depends on the region, so we can store data
 * for multiple environments. */
export const makeUserPoolLocalStorageKey = (config: RuntimeConfig) =>
  `tractable-auth-management-user-pool-id_${config.cognito.region}`;

/** Context for the user pool that's currently selected. */
export const UserPoolContext =
  createSafeContext<UserPoolContext>("UserPoolContext");

export const UserPoolProvider: React.FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const { apiClient } = ApiClientContext.useValue();
  const config = RuntimeConfigContext.useValue();

  // All available user pools
  const [userPools, setUserPools] = useState<UserPool[] | undefined>(undefined);

  // Currently selected user pool
  const [userPool, setUserPool] = useState<UserPool | undefined>(undefined);

  useEffect(() => {
    const run = async () => {
      const result = await apiClient.listUserPools();
      const resultPools = result.userPools;
      resultPools.sort((a, b) => a.clientId.localeCompare(b.clientId));
      setUserPools(resultPools);

      const lastViewedUserPoolId = localStorage.getItem(
        makeUserPoolLocalStorageKey(config)
      );
      const lastViewedUserPool =
        lastViewedUserPoolId &&
        result.userPools.find(
          (pool) => pool.userPoolId === lastViewedUserPoolId
        );

      if (lastViewedUserPool) {
        setUserPool(lastViewedUserPool);
      } else {
        // Assuming there will always be at least one user pool available
        setUserPool(result.userPools[0]);
      }
    };

    run().catch(console.error);
  }, []);

  // Don't render anything that depends on this component until we have all user
  // pools loaded and a user pool selected
  if (!userPools || !userPool) {
    return <Loader />;
  }

  return (
    <UserPoolContext.Provider value={{ userPool, setUserPool, userPools }}>
      {children}
    </UserPoolContext.Provider>
  );
};
